import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import ProductItemResult from '../ProductItem/ProductItemResult';
import { useCount } from '../../context/CountContext';

const Receipt = () => {
  const { state } = useCount();
  const { repurchaseQuantity: quantity } = state;
  const {
    onetime,
    subscription,
    currentCategory,
    bumpOfferChecked,
    bumpOfferIndex
  } = useContext(FunnelContext);
  const isSubscription = currentCategory === 'subscriptions';
  const { products, bumpoffers } = isSubscription ? subscription : onetime;
  const product = products[quantity - 1];
  const { discounted_price: discountedPrice } = product?.checkoutData;

  return (
    <>
      <ProductItemResult
        image={product?.checkoutData?.image}
        displayTitle={product.display_title}
        price={discountedPrice}
        quantity={quantity}
        containerName={product?.productRef?.containerName || 'item'}
      />
      {bumpOfferChecked && (
        <ProductItemResult
          image={bumpoffers[bumpOfferIndex]?.checkoutData?.image}
          displayTitle={bumpoffers[bumpOfferIndex].display_title}
          price={bumpoffers[bumpOfferIndex]?.checkoutData?.discounted_price}
          quantity={1}
          containerName={
            bumpoffers[bumpOfferIndex]?.productRef?.containerName || 'item'
          }
        />
      )}
    </>
  );
};

export default Receipt;
